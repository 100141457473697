<template>
  <div class="relative">
    <app-controls
      v-if="type === 'edit'"
      :blockId="contents.id"
      @actionComplete="blockUpdated"
      @openEdit="blockEdit"
    />
    <div
      :class="{ 'vertical-space': contents.block_space }"
      class="relative fix-height">
      <div class="pan-zoom-container fix-height">
        <img
          :src="contents.image_one_uri"
          alt="" />
      </div>
    </div>

    <div
      v-if="contents.block_layout === 1"
      class="outer-box vertical-space bounceup">
      <div class="half-width">
        <inner-image-zoom
          :src="contents.image_one_uri"
          :zoomSrc="contents.image_one_uri" />
      </div>
      <div class="half-width text-center inner-space pb-5-small">
        <div class="artist">{{ contents.artist }}</div>
        <div class="title">{{ contents.title }}</div>
        <div class="medium">{{ contents.medium }}</div>
        <div class="size">{{ contents.size }}</div>
        <div class="pt-7">
          <button
            @click="makeEnquiry(contents.title)"
            class="button">Enquire</button>
        </div>
        <div
          v-html="contents.free_text"
          class="pt-5"></div>
      </div>
    </div>

    <div
      v-else
      class="outer-box vertical-space bounceup">
      <div class="half-width text-center inner-space pb-5-small">
        <div class="artist">{{ contents.artist }}</div>
        <div class="title">{{ contents.title }}</div>
        <div class="medium">{{ contents.medium }}</div>
        <div class="size">{{ contents.size }}</div>
        <div class="pt-7">
          <button
            @click="makeEnquiry(contents.title)"
            class="button">Enquire</button>
        </div>
        <div
          v-html="contents.free_text"
          class="pt-5"></div>
      </div>
      <div class="half-width">
        <inner-image-zoom
          :src="contents.image_one_uri"
          :zoomSrc="contents.image_one_uri" />
      </div>
    </div>
    <app-enquiry-form
      v-if="enquiry.length > 0"
      @closeEnquiry="enquiry = ''"
      :viewingRoom="contents.viewing_room_id"
      :artwork="enquiry" />
  </div>
</template>

<script>
import Controls from '@/components/Controls.vue';
import InnerImageZoom from 'vue-inner-image-zoom';
import EnquiryForm from '@/components/EnquiryForm.vue';

export default {
  name: 'BlockTwoRender',
  data() {
    return {
      enquiry: '',
    };
  },
  props: [
    'contents',
    'type',
  ],
  components: {
    appControls: Controls,
    'inner-image-zoom': InnerImageZoom,
    appEnquiryForm: EnquiryForm,
  },
  methods: {
    makeEnquiry(title) {
      this.enquiry = title;
    },
    blockUpdated() {
      this.$emit('forceUpdate');
    },
    blockEdit() {
      this.$emit('blockEdit', this.contents.id);
    },
  },
};
</script>
