<template>
  <div>
    <div
      @click="showSettings = true"
        v-if="showSettings === false"
      class="settings">
      <span>
        <img src="../assets/cog-outline.png" alt="" />
      </span>
    </div>
    <div
      v-if="showSettings === true"
      class="settings">
      <span @click="moveUp">
        <img src="../assets/arrow-up-thick.png" alt="Move Up" title="Move Up" />
      </span>
      <span @click="moveDown">
        <img src="../assets/arrow-down-thick.png" alt="Move Down" title="Move Down" />
      </span>
      <span @click="edit">
        <img src="../assets/pencil.png" alt="Edit" title="Edit" />
      </span>
      <span @click="deleteBlock">
        <img src="../assets/delete.png" alt="Delete" title="Delete" />
      </span>
      <span
        @click="showSettings = false">
        <img
          src="../assets/close-box.png"
          alt="Close Settings"
          title="Close Settings" />
      </span>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Controls',
  props: [
    'blockId',
  ],
  data() {
    return {
      showSettings: false,
    };
  },
  methods: {
    moveUp() {
      axios.get(`/blocks/moveUp/${this.blockId}.json?token=${this.$store.state.token}`)
        .then(() => {
          this.$emit('actionComplete');
        });
    },
    moveDown() {
      axios.get(`/blocks/moveDown/${this.blockId}.json?token=${this.$store.state.token}`)
        .then(() => {
          this.$emit('actionComplete');
        });
    },
    edit() {
      this.$emit('openEdit');
    },
    deleteBlock() {
      axios.get(`/blocks/delete/${this.blockId}.json?token=${this.$store.state.token}`)
        .then(() => {
          this.$emit('actionComplete');
        });
    },
  },
};
</script>
