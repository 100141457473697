<template>
  <div class="relative">
    <app-controls
      v-if="type === 'edit'"
      :blockId="contents.id"
      @actionComplete="blockUpdated"
      @openEdit="blockEdit"
    />
    <div
      v-if="contents.block_layout === 1"
      :style="{ backgroundColor: contents.background_color }"
      :class="{ 'vertical-space': contents.block_space }"
      class="outer-box bounceup">
      <div
        :class="{ 'inner-space': contents.image_padded }"
        class="half-width">
        <img
          :src="contents.image_one_uri"
          alt="" />
      </div>
      <div class="half-width inner-space pt-5-small pb-5-small">
        <div v-html="contents.free_text"></div>
      </div>
    </div>

    <div
      v-if="contents.block_layout === 2"
      :style="{ backgroundColor: contents.background_color }"
      :class="{ 'vertical-space': contents.block_space }"
      class="outer-box bounceup">
      <div class="half-width inner-space pt-5-small pb-5-small">
        <div v-html="contents.free_text"></div>
      </div>
      <div
        :class="{ 'inner-space': contents.image_padded }"
        class="half-width">
        <img
          :src="contents.image_one_uri"
          alt="" />
      </div>
    </div>

    <div
      v-if="contents.block_layout === 3">
      <div
        :style="{ backgroundColor: contents.background_color }"
        :class="{ 'vertical-space': contents.block_space, 'inner-space': contents.image_padded }"
        class="outer-box bounceup">
        <img
          :src="contents.image_one_uri"
          alt="" />
      </div>
      <div
        :style="{ backgroundColor: contents.background_color }"
        class="outer-box">
        <div class="inner-space-all" v-html="contents.free_text"></div>
      </div>
    </div>

    <div
      class="bounceup"
      :class="{ 'vertical-space': contents.block_space }"
      v-if="contents.block_layout === 4">
      <div
        :style="{ backgroundColor: contents.background_color }"
        class="outer-box">
        <div class="inner-space-all" v-html="contents.free_text"></div>
      </div>
      <div
        :style="{ backgroundColor: contents.background_color }"
        :class="{ 'inner-space': contents.image_padded }"
        class="outer-box">
        <img
          :src="contents.image_one_uri"
          alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Controls from '@/components/Controls.vue';

export default {
  name: 'BlockThreeRender',
  props: [
    'contents',
    'type',
  ],
  components: {
    appControls: Controls,
  },
  methods: {
    blockUpdated() {
      this.$emit('forceUpdate');
    },
    blockEdit() {
      this.$emit('blockEdit', this.contents.id);
    },
  },
};
</script>
