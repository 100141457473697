<template>
  <div class="relative">
    <app-controls
      v-if="type === 'edit'"
      :blockId="contents.id"
      @actionComplete="blockUpdated"
      @openEdit="blockEdit"
    />
    <div
      :class="{ 'vertical-space': contents.block_space }"
      class="outer-box"
      :style="{ backgroundColor: contents.background_color }">
      <div
        :class="{ 'inner-space': contents.image_padded }"
        class="full-width pb-10 pt-10">
        <p v-if="contents.title" class="large">{{ contents.title }}</p>
        <div v-html="contents.video"></div>
        <p v-if="contents.caption">
          <i>{{ contents.caption }}</i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Controls from '@/components/Controls.vue';

export default {
  name: 'BlockSixRender',
  props: [
    'contents',
    'type',
  ],
  components: {
    appControls: Controls,
  },
  methods: {
    blockUpdated() {
      this.$emit('forceUpdate');
    },
    blockEdit() {
      this.$emit('blockEdit', this.contents.id);
    },
  },
};
</script>
