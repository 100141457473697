<template>
  <div class="modal">
    <div class="modal-inner">
      <span
        @click="closeEnquiry"
        class="modal-close">X</span>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12 medium-2">
          <img class="logo" src="../assets/logo.png" alt="Messums" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12">
          <h4>Enquiry about {{ artwork }}</h4>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12 medium-6">
          <label>Your name</label>
          <input v-model="name" type="text" />
          <label>Email address</label>
          <input v-model="email" type="email" />
          <label>Phone number</label>
          <input v-model="contactNumber" type="text" />
        </div>
        <div class="cell small-12 medium-6">
          <label>Message</label>
          <textarea v-model="message"></textarea>
          <button
            class="button"
            @click="sendEnquiry">Send enquiry</button>
          <p class="small pt-5">
            We will process your personal data in accordance with our
            <a href="https://www.messums.com/privacy" target="_blank">privacy
            policy.</a> You can unsubscribe or change your preferences at any time by clicking the
            link in any emails we send.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'EnquiryForm',
  props: [
    'viewingRoom',
    'artwork',
  ],
  data() {
    return {
      email: '',
      contactNumber: '',
      name: '',
      message: '',
    };
  },
  methods: {
    sendEnquiry() {
      const postData = {};
      postData.email = this.email;
      postData.contactNumber = this.contactNumber;
      postData.name = this.name;
      postData.message = this.message;
      postData.viewingRoom = this.viewingRoom;
      postData.artwork = this.artwork;
      axios.post('/enquiries/addEnquiry.json', postData)
        .then(() => {
          this.$emit('closeEnquiry');
        });
    },
    closeEnquiry() {
      this.$emit('closeEnquiry');
    },
  },
};
</script>

<style>
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(51, 51, 51, 0.9);
  }

  .modal-inner {
    position: absolute;
    top: 10%;
    right: 10%;
    left: 10%;
    z-index: 3;
    background-color: #FFF;
    border: 1px solid #7c7c7c;
    border-radius: 3px;
    padding-bottom: 30px;
  }

  .modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #7C7C7C;
    color: #FFF;
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
  }
  .modal-close:hover,
  .modal-close:focus {
    background-color: #AAA;
  }
</style>
