<template>
  <div class="relative">
    <app-controls
      v-if="type === 'edit'"
      :blockId="contents.id"
      @actionComplete="blockUpdated"
      @openEdit="blockEdit"
    />
    <div
      :class="{ 'vertical-space': contents.block_space }"
      class="outer-box bounceup"
      :style="{ backgroundColor: contents.background_color }">
      <div
        :class="{ 'inner-space-all': contents.image_padded }"
        class="half-width">
        <inner-image-zoom
            :src="contents.image_one_uri"
            :zoomSrc="contents.image_one_uri" />
      </div>
      <div
        :class="{ 'inner-space-all': contents.image_padded }"
        class="half-width">
        <inner-image-zoom
            :src="contents.image_two_uri"
            :zoomSrc="contents.image_two_uri" />
      </div>
    </div>
    <div
      class="outer-box pb-10"
      :style="{ backgroundColor: contents.background_color }">
      <div class="half-width text-center">
        <div class="artist">{{ contents.left_artist }}</div>
        <div class="title">{{ contents.left_title }}</div>
        <div class="medium">{{ contents.left_medium }}</div>
        <div class="size">{{ contents.left_size }}</div>
        <div class="pt-7">
          <button
            @click="makeEnquiry(contents.left_title)"
            class="button">Enquire</button>
        </div>
      </div>
      <div class="half-width text-center">
        <div class="artist">{{ contents.right_artist }}</div>
        <div class="title">{{ contents.right_title }}</div>
        <div class="medium">{{ contents.right_medium }}</div>
        <div class="size">{{ contents.right_size }}</div>
        <div class="pt-7">
          <button
            @click="makeEnquiry(contents.right_title)"
            class="button">Enquire</button>
        </div>
      </div>
    </div>
    <app-enquiry-form
      v-if="enquiry.length > 0"
      @closeEnquiry="enquiry = ''"
      :viewingRoom="contents.viewing_room_id"
      :artwork="enquiry" />
  </div>
</template>

<script>
import Controls from '@/components/Controls.vue';
import InnerImageZoom from 'vue-inner-image-zoom';
import EnquiryForm from '@/components/EnquiryForm.vue';

export default {
  name: 'BlockFiveRender',
  props: [
    'contents',
    'type',
  ],
  data() {
    return {
      enquiry: '',
    };
  },
  components: {
    appControls: Controls,
    'inner-image-zoom': InnerImageZoom,
    appEnquiryForm: EnquiryForm,
  },
  methods: {
    makeEnquiry(title) {
      this.enquiry = title;
    },
    blockUpdated() {
      this.$emit('forceUpdate');
    },
    blockEdit() {
      this.$emit('blockEdit', this.contents.id);
    },
  },
};
</script>
