<template>
  <div class="relative">
    <app-controls
      v-if="type === 'edit'"
      :blockId="contents.id"
      @actionComplete="blockUpdated"
      @openEdit="blockEdit"
    />
    <div
      :class="{ 'vertical-space': contents.block_space }"
      class="outer-box bounceup">
      <div
        :class="{ 'inner-space': contents.image_padded }"
        class="full-width">
        <p v-if="contents.title">{{ contents.title }}</p>
        <img
          :src="contents.image_one_uri"
          alt="" />
        <div v-if="contents.audio">
          <audio
            class="pt-2"
            controls>
            <source :src="contents.audio" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </div>
        <p v-if="contents.caption">
          <i>{{ contents.caption }}</i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Controls from '@/components/Controls.vue';

export default {
  name: 'BlockOneRender',
  props: [
    'contents',
    'type',
  ],
  components: {
    appControls: Controls,
  },
  methods: {
    blockUpdated() {
      this.$emit('forceUpdate');
    },
    blockEdit() {
      this.$emit('blockEdit', this.contents.id);
    },
  },
};
</script>
